// import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
import React from 'react';
import Home from './Components/Pages/Home';

function App() {
  return (
    <div>
        <Home />

    </div>
    
  );
}

export default App;
